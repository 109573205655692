import React, { Component } from 'react';
import './ratingPage.css'
import ratingImg from '../../MenuComponents/img/ratingImg.svg'
import LocalizedStrings from 'react-localization';
import { Link, Redirect } from 'react-router-dom'
import { Form, Checkbox, Divider, TextArea, Button, Icon } from 'semantic-ui-react'
import { firestore, auth } from 'firebase'
class RatingPage extends Component {
    state = {
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
        step: 0,
        selectedRate: null
    }
    strings = new LocalizedStrings({
        en: {
            orderSent: "Your Order has been sent 👏",
            trackOrder: "Track order",
            RateExperience: "Rate Experience",
            SorryToHear: "Sorry to hear That",
            WhatWentWrong: "What went wrong?",
            FoodWasCold: "Food was cold",
            FoodWasDamaged: "Food was damaged",
            PoorFoodQuality: "Poor food quality",
            MissingWrongItems: "Missing or wrong items",
            LeaveComment: "Leave a comment",
            ThankYou: "Thank you",
            YourFeedback: "Your feedback will help us improve our services we provide to you",
            terrible: "Terrible",
            bad: "Bad",
            average: "Average",
            good: "Good",
            awsome: "Awsome",
            continue: "Continue",
            submit: "submit",
            backToMenu: "Back To Menu",
            howWasFood: "How was the food?",
            pleaseRate: "please rate the food experience for recent order",


        },
        ar: {
            orderSent: "تم إرسال طلبك 👏",
            trackOrder: "تتبع طلبك",
            RateExperience: "تقييم التجربة",
            SorryToHear: "نأسف لسماع ذلك",
            WhatWentWrong: "ما الخطأ الذي حدث؟",
            FoodWasCold: "كان الطعام باردا",
            FoodWasDamaged: "تضرر الطعام",
            PoorFoodQuality: "نوعية طعام رديئة",
            MissingWrongItems: "عناصر مفقودة أو خاطئة",
            LeaveComment: "اترك تعليقا",
            ThankYou: "شكرا لك",
            YourFeedback: "ستساعدنا ملاحظاتك في تحسين خدمتنا التي نقدمها لك",
            terrible: "رهيب",
            bad: "سيئة",
            average: "متوسط",
            good: "جيد",
            awsome: "رائع",
            continue: "متابعة",
            submit: "إرسال",
            backToMenu: "عودة إلى القائمة",
            howWasFood: "كيف كان الطعام؟",
            pleaseRate: "يرجى تقييم تجربة الطعام للطلب الأخير",

        }
    });
    componentWillMount() {
        let { direction } = this.state
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
    }
    handleChange = (e, { value }) => {
        let { selectedRate } = this.state
        selectedRate["badRate"] = value
        this.setState({ selectedRate })
    }
    handleInputChange = (e, { value }) => {
        let { selectedRate } = this.state
        selectedRate["comment"] = value
        this.setState({ selectedRate })
    }
    submit() {
        let { selectedRate } = this.state
        let key = window.resKey
        let { orderId } = this.props
        selectedRate.userUId = auth().currentUser ? auth().currentUser.uid : 'non'

        if (!orderId) {
            let url = new URL(window.location.href)
            orderId = url.searchParams.get(orderId)
        }
        if (key) {
            let id = firestore().collection('resturant').doc(key).collection('ratings').doc().id

            if (auth().currentUser) {
                firestore().collection('users').doc(auth().currentUser.uid).get().then(results => {
                    let data = results.data()
                    let { name } = data
                    selectedRate.user = {
                        name: name ? name : auth().currentUser.phoneNumber,
                        phone: auth().currentUser.phoneNumber
                    }
                    selectedRate.created = Date.now()
                    selectedRate.orderId = orderId
                    firestore().collection('resturant').doc(key).collection('ratings').doc(id).set(selectedRate).then(() => {
                        window.dataLayer.push({
                            'userID': auth().currentUser.uid,
                            'resKey': window.resKey,
                            'rate': selectedRate,
                            'event': 'Feedback'
                        })
                        firestore().collection('resturant').doc(key).collection('orders').doc(orderId).update({
                            rated: true
                        })
                        this.dismissView()
                    }).catch(() => {
                        this.dismissView()
                    })

                })
            } else {
                this.dismissView()
            }
        } else {
            this.dismissView()
        }

    }
    dismissView() {
        let { dismiss } = this.props
        if (dismiss) {
            dismiss()
        } else {
            this.setState({ redirect: true })
        }
    }
    render() {
        let { direction, step, selectedRate, redirect, loading } = this.state
        let strings = this.strings
        let issues = [strings.FoodWasCold, strings.PoorFoodQuality, strings.MissingWrongItems]
        let ratings = [{ emoji: "🤢", text: strings.terrible }, { emoji: "😐", text: strings.bad }, { emoji: "🙂", text: strings.average }, { emoji: "😊", text: strings.good }, { emoji: "😍", text: strings.awsome }]
        let path = window.pathName

        if (redirect) {
            return (<Redirect to={path ? '/' + path : '/'} push={true} />)
        }
        return (
            <div className='ratingClass' style={(step === 2) ? { justifyContent: 'center' } : {}} >
                {(step === 0 || step === 1) &&
                    <Link to={path ? '/' + path : '/'} onClick={() => this.dismissView()} className='iconTimes' > <Icon name='times' /></Link>
                }

                {
                    step === 0 ? (
                        <div className='rateExperienceContainer' >

                            <div className='title' >
                                {strings.RateExperience}
                                <div className='underline' />
                            </div>
                            <div className='sorryContainer' >
                                <div className='upperText' >{strings.howWasFood}</div>
                                <div className='lowerText' >{strings.pleaseRate}</div>
                            </div>
                            <div className='emojiContainer'>
                                {
                                    ratings.map((item, index) => {
                                        return (
                                            <div className='emojiItem' key={index} onClick={() => { this.setState({ selectedRate: { rate: item, index: index } }) }}>
                                                <div className='emoji' style={(selectedRate && selectedRate.index === index) ? { backgroundColor: 'var(--themeColor)' } : {}} >{item.emoji}</div>
                                                <div className='text'>{item.text}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    ) : step === 1 ? (
                        <div className='rateExperienceContainer' >
                            <div className='title' >
                                {strings.RateExperience}
                                <div className='underline' />
                            </div>
                            <div className='sorryContainer' >
                                <div className='upperText' >{strings.SorryToHear}</div>
                                <div className='lowerText' >{strings.WhatWentWrong}</div>
                            </div>
                            <Form className='checkboxContainer'>
                                {
                                    issues.map((item, index) => {
                                        return (
                                            <Form.Field key={index} >
                                                <Checkbox
                                                    radio
                                                    label={item}
                                                    className={direction === 'rtl' ? 'floatright' : ''}
                                                    value={item}
                                                    checked={this.state.selectedRate.badRate === item}
                                                    onChange={this.handleChange}
                                                />
                                                <Divider />
                                            </Form.Field>
                                        )
                                    })
                                }

                            </Form>
                            <Form>
                                <TextArea rows={1} placeholder={strings.LeaveComment} onChange={this.handleInputChange} />
                            </Form>
                        </div>
                    ) : (
                                <div className='thankyouClassContainer' >
                                    <img className='checkimg' src={ratingImg} alt='' />
                                    <div className='holatext' >{strings.ThankYou}</div>
                                    <div className='yourordertext' >{strings.YourFeedback}</div>
                                </div>
                            )
                }

                {
                    (step !== 2) ? (<Button className='submitButton' disabled={!selectedRate} onClick={() => {
                        if (selectedRate && selectedRate.index > 2) {
                            this.setState({ step: 2 })
                        } else {
                            this.setState({ step: step + 1 })
                        }
                    }}>{[strings.continue, strings.submit, strings.backToMenu][step]}</Button>
                    ) : (
                            <Button loading={loading} className='submitButton' onClick={() => this.setState({ loading: true }, () => this.submit())}>{[strings.continue, strings.submit, strings.backToMenu][step]}</Button>
                        )
                }

            </div>
        )
    }

}


export default RatingPage
