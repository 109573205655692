import React, { Component } from 'react';
import './cart.css'
import Btn from '../Helpers/AddItemBtn/btn'
import 'semantic-ui-css/semantic.min.css'
import { Input, Button, Dimmer, Icon, TextArea, Dropdown, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { flushOrders } from '../../reducers/newOrder'

import { updateUserLocation, updateUserAddress, setHelpers } from '../../reducers/userReducer'
import NewLogin from '../Login/newLogin';
import ThankYouPage from './thankyoupage/thankyoupage'
import Header from '../Header/index';
import { applyCoupone, calcGrandTotal, placeOrder, validateUser, calcItemTotal, getAddedExtras } from '../../helpers/cartHelpres';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import Extras from '../MenuComponents/extra/extra';
import Banner from '../MenuComponents/offlineBanner/offlineBanner'
import { firestore, auth, functions } from 'firebase';
import Payment from './payment.js'
import SelectLocation from '../MenuComponents/selectLocation/selectLocation'
import MenuItem from '../MenuComponents/item-page/item.js'
import moment from 'moment';
function localizeString(object, field) {
    let direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl';

    if (object) {
        if (direction === 'rtl' && object[field + '_ar']) {
            return object[field + '_ar']
        } else {
            return object[field]
        }
    }
    return ''

}
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
            pageStep: 0,
            fromCart: true,
            selectedPayment: 'cash',
            loading: false,
            cardObject: {}

        }
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        // this.submitNewAddress = this.submitNewAddress.bind(this)

    }
    handleScriptLoad() {
        // Declare Options For Autocomplete 
        // var circle = new google.maps.Circle(
        //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });

        var options = {
            types: ['geocode'],
            componentRestrictions: { country: 'ae' }
        };

        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocompleteadd'),
            options);
        // this.autocomplete.setBounds(circle.getBounds());

        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',
            'formatted_address', 'geometry', 'type', 'name', 'vicinity']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect);
    }
    handlePlaceSelect() {
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;
        let area = addressObject.name
        // Check if address is valid
        if (address) {
            // Set State
            this.setState(
                {
                    area,
                    address: addressObject.formatted_address,
                    location: {
                        lat: addressObject.geometry.location.lat(),
                        lng: addressObject.geometry.location.lng()
                    }
                }
            );
        }
    }

    strings = new LocalizedStrings({
        en: {
            name: 'Name',
            yoyrcart: 'Your Cart',
            Checkout: "Checkout",
            subtotal: 'Sub Total',
            vat: 'VAT',
            discount: 'Discount',
            total: "Total",
            delivery: "Delivery charge",
            applyCoupone: "Apply coupon",
            cartEmpty: "Your cart is empty",
            addItemsFrom: "Add items from ",
            toPlaceOrder: " to place order",
            promoCode: "promo code",
            placeOrder: "PLACE ORDER",
            continue: 'Continue',
            breakfast: "Breakfast",
            dinner: "Dinner",
            soup: "Soup",
            freeItem: "Free Item",
            cash: "Cash on delivery",
            card: "Card on delivery",
            online: 'Online Payment',
            cashPickup: "Cash on pickup",
            cardPickup: "Card on pickup",
            onlinePickup: "Online Payment",
            cashDineIn: "Cash on leave",
            cardDineIn: "Card on leave",
            onlineDineIn: 'Online Payment',
            search: "Search for food 😋",
            login: "Login",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            menu: "Menu",
            InclusiveOfVat: "(Inclusive of VAT)",
            remove: 'Remove',
            deliveringTo: 'Delivering to',
            pickupfrom: "Pickup From",
            servingTo: "Serving to",

            paymentMethod: 'Payment method',
            addressDetails: 'Address Details',
            areaName: "Area Name",
            fullAddress: 'Full Address',
            email: 'Email',
            building: 'Building',
            street: 'Street',
            floor: 'Floor',
            flat: 'Flat',
            addressLabel: 'Address Label',
            notes: 'Other notes for driver',
            saveAddress: 'Save address',
            addNewAddress: '+Add new address',
            specialNotes: 'Special Notes',
            Change: "Change",
            WhereToDeliver: "Where to deliver?",
            addNewCard: '+ Add New Card',
            selectAddress: 'Select Address',
            added: 'Added ',
            tableNumber: 'Table: ',
            save: 'Save'
        },
        ar: {
            name: 'الاسم',
            yoyrcart: 'قائمة الطلبات',
            Checkout: "الدفع",
            subtotal: 'مبلغ إجمالي',
            vat: 'ضريبة',
            discount: 'خصم',
            total: "إجمالي",
            delivery: "رسوم التوصيل",
            applyCoupone: "تطبيق القسيمة",
            cartEmpty: "قائمة التسوق خالية",
            addItemsFrom: "أضف الطلبات من ",
            toPlaceOrder: " لوضع الطلب",
            promoCode: "الرمز الترويجي",
            placeOrder: "تأكيد الطلب",
            continue: 'استمر',
            breakfast: "فطور",
            dinner: "غداء",
            soup: "حسااء",
            freeItem: "مجاني",
            cash: "الدفع كاش عند الاستلام",
            card: "الدفع بالبطاقة عند الاستلام",
            online: 'الدفع الالكتروني',
            cashPickup: "الدفع كاش عند الاستلام",
            cardPickup: "الدفع بالبطاقة عند الاستلام",
            onlinePickup: "الدفع الالكتروني",

            cashDineIn: "الدفع كاش عند المغادرة",
            cardDineIn: "الدفع بالبطاقة عند المغادرة",
            onlineDineIn: 'الدفع الالكتروني',
            search: "بحث عن الطعام 😋",
            login: "تسجيل الدخول",
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
            menu: "القائمة",
            InclusiveOfVat: "(تتضمن ال VAT)",
            remove: 'إلغاء',
            deliveringTo: 'توصل إلى',
            pickupfrom: "استلام الطلب من",
            servingTo: "تقدم إلى",

            paymentMethod: 'طريقة الدفع',
            addressDetails: 'تفاصيل العنوان',
            areaName: "اسم المنطقة",
            fullAddress: 'العنوان',
            addressLabel: 'تسمية عنوان',
            notes: 'ملاحظات أخرى للسائق',
            email: 'البريد الإلكتروني',
            building: 'مبنى',
            street: 'شارع',
            floor: 'طابق',
            flat: 'شقة',
            saveAddress: 'حفظ العنوان',
            addNewAddress: '+ أضف عنوان جديد',
            specialNotes: 'ملاحظات خاصة',
            Change: "تغيير",
            WhereToDeliver: "إلى أين يتم التوصيل؟",
            addNewCard: '+ إضافة بطاقة جديدة',
            selectAddress: 'حدد العنوان',
            added: 'إضافة ',
            tableNumber: 'طاولة رقم : ',
            save: 'تم'

        }
    });
    componentWillMount() {
        let { direction, cardObject } = this.state
        let { user, userAddressProp, selectedLocation } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
        let _this = this
        var url = new URL(window.location.href);
        var tap_id = url.searchParams.get("tap_id");
        if (tap_id) {
            console.log("tap_id", tap_id)
            this.setState({ proccessPayment: true }, () => {
                var checkAuth = functions().httpsCallable('AuthRedirect');
                checkAuth({ authId: tap_id }).then(function (result) {
                    let { data } = result
                    console.log("checkAuth", data)
                    let { card, customer, status, errors } = data
                    if (errors) {
                        cardObject = user.info.card
                        return _this.setState({ tokenId: tap_id, cardObject, error: errors[0].description, loading: false, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                    }

                    if (status === "AUTHORIZED") {
                        card.customer = customer
                        if (card && card.id) {
                            firestore().collection('users').doc(auth().currentUser.uid).update({
                                [`card.${card.id}`]: card
                            }).then(() => {
                                _this.setState({ placeOrder: true, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                            })
                        } else {
                            _this.setState({ placeOrder: true, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                        }
                        _this.props.flushOrders()
                    } else {
                        _this.setState({ error: 'Cannot process your card', proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })

                    }
                }).catch(error => {
                    console.log("errorerror", error)

                })
            })
        }
        let closed = window.closedOn
        if (closed) {
            let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
            let nowTime = nowOptimized.toDate().getTime()
            let from = new Date('1955', '0', '05', closed.from, closed.minFrom).getTime()
            let to = new Date('1955', '0', '05', closed.to, closed.minTo).getTime()

            if (closed.from > closed.to ? (to > nowTime) || (nowTime > from) : (to > nowTime) && (nowTime > from)) {
                setTimeout(() => {
                    this.setState({ showOfflineBanner: true, disableOrder: true })
                }, 500);
            } else {
                this.setState({ showOfflineBanner: false, disableOrder: false })
            }
        }
        if (user && user.info && user.info.card) {
            this.setState({ cardObject: user.info.card })
        }
        window.scrollTo({
            top: '0'
        })
    }

    render() {
        let { orderList, user, selectedLocation, allLocations, loyalty, helpers } = this.props
        let { percent, cap, freeItem, discountApplied, coupon, couponeCode } = helpers ? helpers : {}

        let { errorMinOrder, login, placeOrder, direction, extras, showOfflineBanner, selectAddress, pageStep, selectedAddress, fromCart, loading, addcard, proccessPayment, cardObject } = this.state
        let strings = this.strings
        let count = (orderList ? Object.keys(orderList) : []).map(item => {
            return orderList[item].count
        }).reduce((a, b) => a + b, 0)
        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, selectedLocation, loyalty)
        let path = window.pathName

        let earningPoints = ((receipt.subTotal - (receipt.deductedValue ? receipt.deductedValue : 0)) * (loyalty.percent / 100)).toFixed(2)


        if (placeOrder) {
            return (<ThankYouPage user={user} />)
        }
        if (proccessPayment) {
            return (

                <>
                    <Dimmer active>
                        <Loader content='Processing Payment' />
                    </Dimmer>
                </>
            )
        }
        if (extras) {
            return (
                <div className='cartSection' style={{ direction: direction }}>
                    {/* <div className='leftSide'> */}
                    {
                        extras.removeItem ?
                            <Extras menuItem={extras} dismiss={() => this.setState({ extras: null })} />
                            :
                            <MenuItem
                                removeRedirect={() => this.setState({ redirect: false })}
                                selectedItem={extras}
                                helpers={helpers}
                                direction={direction}
                                goBack={() => {
                                    this.setState({ extras: false })
                                }} />
                    }

                    {/* </div> */}
                    {/* <div className='rightSide' style={direction === 'rtl' ? { left: '0px', right: 'auto', backgroundImage: window.sideImage } : { left: 'auto', right: '0px', backgroundImage: window.sideImage }}>
                        <img className='sideImage' alt='sideImage' src={window.sideImage}></img>
                    </div> */}
                </div>
            )

        }
        return (
            <div className='cartSection' style={{ direction: direction }}>
                {login ?
                    <NewLogin
                        dismiss={() => this.setState({ login: false })}
                        success={() => this.setState(validateUser(this.props.user, receipt, selectedLocation, allLocations))}>

                    </NewLogin>
                    : ''

                }
                {
                    showOfflineBanner && <Banner hide={() => this.setState({ showOfflineBanner: false })} />
                }
                {
                    (!showOfflineBanner && selectedLocation && selectedLocation.active && !selectedLocation.active.active) && <Banner active={selectedLocation.active} hide={() => this.setState({ showOfflineBanner: false })} />
                }

                <Header
                    count={count}
                    direction={direction}
                    fromCart={fromCart}
                    title={pageStep === 0 ? strings.yoyrcart : pageStep === 1 ?
                        (addcard ? strings.addNewCard : strings.Checkout)
                        : pageStep === 2 ?
                            strings.selectAddress
                            : pageStep === ''}
                    back={() => {
                        pageStep !== 0 ?
                            (addcard ? this.setState({ addcard: false })
                                : this.setState({ pageStep: selectedAddress ? pageStep - 1 : 0 }))
                            : window.history.back()
                    }
                    } />

                {

                    pageStep === 0 ?
                        <div className='cartDetail' >
                            {/* <div className='title'>
                                    {strings.yoyrcart}
                                </div> */}
                            {
                                Object.keys(orderList).length ?

                                    <div style={{ minHeight: '60%' }}>
                                        <div >
                                            <div className='cartList'>
                                                {
                                                    Object.keys(orderList).map((itemKey, index) => {
                                                        let item = orderList[itemKey]
                                                        let excluded = item.excluded && item.excluded[couponeCode]
                                                        let addedExtras = getAddedExtras(item)
                                                        let itemTotalPrice = calcItemTotal(item)
                                                        if (addedExtras && addedExtras.length) {
                                                            return addedExtras.map((extraRecord, innerIndex) => {
                                                                let duplicates = extraRecord.addons

                                                                return (
                                                                    <div key={innerIndex} className='cartItem' style={{ borderBottom: '1px solid #cccccc40' }}>
                                                                        <div style={{ maxWidth: '70%' }}>
                                                                            <div>{direction === 'rtl' && extraRecord.name_ar ? extraRecord.name_ar : extraRecord.name}</div>
                                                                            <div className='itemPrice'>
                                                                                {
                                                                                    discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: direction === 'rtl' ? '0 0 0 8px' : '0 8px 0 0', color: '#adadad' }}>{extraRecord.price + strings[window.currency] + ' '}</span>
                                                                                }
                                                                                {
                                                                                    ((discountApplied && !excluded) ? ((extraRecord.price * discountApplied).toFixed(2) + strings[window.currency]) : (extraRecord.price + strings[window.currency]))
                                                                                }



                                                                            </div>
                                                                            {
                                                                                extraRecord.addons.map((item, pos) => {
                                                                                    let extraCount = extraRecord.addons?.filter(rec => rec === item)?.length
                                                                                    if (extraRecord.addons.indexOf(item) === pos) {
                                                                                        return <div key={pos} className="itemDetails">{strings.added + extraCount + "x " + item}</div>
                                                                                    }
                                                                                })
                                                                            }
                                                                            {
                                                                                item.specialNotes && <div className="itemDetails">"{item.specialNotes}"</div>
                                                                            }
                                                                        </div>
                                                                        <div className='btnHolder'>
                                                                            <Btn counter={extraRecord.count} menuItem={item} showExtras={(extras) => this.setState({ extras })} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            )
                                                        }

                                                        return (
                                                            <div key={index} className='cartItem' style={{ borderBottom: '1px solid #cccccc40' }}>
                                                                <div style={{ maxWidth: '70%' }}>
                                                                    <div>{direction === 'rtl' && item.name_ar ? item.name_ar : item.name}</div>
                                                                    <div className='itemPrice'>
                                                                        {
                                                                            discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: direction === 'rtl' ? '0 0 0 8px' : '0 8px 0 0', color: '#adadad' }}>{calcItemTotal(item) + strings[window.currency] + ' '}</span>
                                                                        }
                                                                        {
                                                                            (discountApplied && !excluded ? ((itemTotalPrice * discountApplied).toFixed(2) + strings[window.currency]) : (calcItemTotal(item) + strings[window.currency]))
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className='btnHolder'>
                                                                    <Btn menuItem={item} showExtras={(extras) => this.setState({ extras })} />
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }

                                                <div className='notes'>
                                                    <div>{strings.specialNotes}</div>
                                                    <TextArea
                                                        onChange={(e, { value }) => { this.setState({ specialNotes: value }) }}
                                                        type=''
                                                        placeholder={strings.specialNotes}
                                                        style={{
                                                            margin: '8px 0',
                                                            width: '100%',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            outline: 'none',
                                                            fontSize: '12px',
                                                            fontWeight: '100',
                                                            minHeight: '90px',
                                                            backgroundColor: '#f5f5f5',
                                                            padding: '8px'
                                                        }}>

                                                    </TextArea>
                                                </div>
                                            </div>
                                            <div className='separator'></div>

                                            {
                                                loyalty && <div className='discountContainer' style={{ paddingTop: '18px' }}>
                                                    <div className='discountContainer2' style={{ backgroundColor: 'var(--counterBtn)' }}>
                                                        <div className='text' style={{ color: 'var(--themeColor)' }}>
                                                            <Icon className='notificationicon' name='gift' />
                                                            {<div>{direction === 'rtl' ? 'ستحصل على ' : 'You will earn '}
                                                                <span style={{ fontSize: '14px', color: 'var(--themeColor)' }}>
                                                                    {earningPoints}
                                                                </span>
                                                                {direction === 'rtl' ? ' نقاط ولاء عند إكمال هذا الطلب ' : ' Loyalty Points when you complete this order'}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            <div className='listDetails' style={{ marginBottom: '90px' }}>
                                                <div className='subDetail'>
                                                    <div>{strings.subtotal}</div>
                                                    <div>{receipt.subTotal + strings[window.currency]}</div>
                                                </div>
                                                {/* <div className='subDetail'>
                                        <div>{strings.vat}</div>
                                        <div>{receipt.vat + strings[window.currency]}</div>
                                    </div> */}
                                                {
                                                    receipt.delivery &&
                                                    <div className='subDetail'>
                                                        <div>{strings.delivery}</div>
                                                        <div>{receipt.delivery + strings[window.currency]}</div>
                                                    </div>
                                                }
                                                {
                                                    receipt.deductedValue ?
                                                        <div className='subDetail' style={{ color: 'var(--themeColor)' }}>
                                                            <div>{strings.discount} <span style={{ fontSize: '10px', color: '#cccccc' }}>({coupon ? coupon.code : 'loyalty pts'})</span></div>
                                                            <div>{" - " + receipt.deductedValue + strings[window.currency]}</div>
                                                        </div>
                                                        : ''
                                                }

                                                <div className='mainDetail'>
                                                    <div>{strings.total} <span style={{ fontSize: '10px', color: '#cccccc' }}>{strings.InclusiveOfVat}</span></div>
                                                    <div> {(receipt.total) + strings[window.currency]}</div>
                                                </div>

                                                {
                                                    errorMinOrder &&
                                                    <div className='error'>

                                                        <div className='discountContainer'>
                                                            <div className='discountContainer2'>
                                                                <div className='text'>
                                                                    <Icon className='notificationicon' name='info' />
                                                                    {errorMinOrder}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='actionBtn'>
                                            <Button loading={loading} className='placeOrderBtn' disabled={this.state.disableOrder || (selectedLocation && selectedLocation.active && !selectedLocation.active.active)} onClick={() => {
                                                this.setState({ loading: true }, () => {
                                                    setTimeout(() => {
                                                        let newState = validateUser(user, receipt, selectedLocation, allLocations)
                                                        this.setState(newState, () => {
                                                            let { errorMinOrder } = newState
                                                            if (errorMinOrder) {
                                                                window.scrollTo(0, document.body.scrollHeight);
                                                            }
                                                        })
                                                    }, 1000)
                                                })
                                            }}>{strings.continue}</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className='empty' style={{ textAlign: 'center' }}>
                                        <div className='title'>
                                            {strings.cartEmpty}
                                        </div>
                                        <div className='detail'>
                                            {strings.addItemsFrom} <Link to={path ? ('/' + path) : '/'}>{strings.menu}</Link> {strings.toPlaceOrder}
                                        </div>
                                    </div>
                            }

                        </div>
                        : pageStep === 2 ?
                            this.renderAddAddress()
                            : pageStep === 1 && selectedAddress ?
                                addcard ?
                                    <Payment success={(res) => {
                                        cardObject[res.card.id] = res.card;
                                        this.setState({
                                            cardObject,
                                            addcard: false,
                                            selectedCard: res.card,
                                            tokenId: res.id,
                                            error: false
                                        })
                                    }}
                                        direction={direction}
                                    />
                                    :
                                    this.renderCheckout()
                                : ''

                }

                {
                    selectAddress && this.renderAddressPicker()
                }


            </div >
        )
    }
    renderCheckout() {
        let strings = this.strings
        let { orderList, user, selectedLocation, allLocations, loyalty, helpers } = this.props
        let { percent, cap, freeItem, coupon } = helpers ? helpers : {}
        let { selectedAddress, selectedPayment, specialNotes, loading, direction, selectedCard, cardObject, error } = this.state
        selectedAddress.fees = selectedLocation.fees
        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, selectedAddress, loyalty)
        let _this = this

        if (this.state.changeTable) {
            return <SelectLocation direction={direction} dismiss={(area) => this.setState(validateUser(user, receipt, area, allLocations))} branch={selectedLocation.branches[0]} />
        }
        return (
            <div className='cartDetail'>
                <div className='section'>

                    <div className='title'>
                        {selectedLocation.type === 'pickup' ? strings.pickupfrom : selectedLocation.type === 'dineIn' ? strings.servingTo : strings.deliveringTo}
                    </div>

                    {
                        selectedAddress ?
                            <div className='cartList' style={{ fontWeight: '600' }}>
                                <div key={"index"} className='cartItem' style={{ border: 'none', padding: '8px 0' }}>
                                    <div>
                                        <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>

                                        {
                                            selectedAddress.type === 'pickup' ?
                                                <>
                                                    {localizeString(selectedAddress, 'area')}
                                                    <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                        {localizeString(selectedAddress, 'address')}
                                                    </div>
                                                </>

                                                : selectedAddress.type === 'dineIn' ?
                                                    strings.tableNumber + selectedAddress.location

                                                    :

                                                    <>
                                                        {selectedAddress.label}
                                                        <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                            {(selectedAddress.area.name ? (direction === 'rtl' ? selectedAddress.area.name_ar : selectedAddress.area.name) : localizeString(selectedAddress, 'area')) + " - " + selectedAddress.fullAddress}
                                                        </div>
                                                    </>
                                        }


                                    </div>
                                    {selectedLocation.type !== 'pickup' && <Button compact className="removeBtn" basic onClick={() => {
                                        if (selectedLocation.type === 'dineIn') {
                                            this.setState({ changeTable: true })
                                        } else {
                                            this.setState({ selectAddress: true })
                                        }
                                    }
                                    }>{strings.Change}</Button>}
                                </div>

                            </div>
                            :
                            <div className='cartList' style={{ fontWeight: '600' }}>
                                <div key={"index"} className='cartItem' >
                                    <div><span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>{user && user.info ? user.info.area : ''}</div>
                                    <Button compact className="removeBtn" basic onClick={() => this.setState({ selectAddress: true })}>{strings.Change}</Button>
                                </div>
                            </div>
                    }
                </div>

                <div className='section'>

                    <div className='title'>
                        {strings.paymentMethod}
                    </div>

                    <div className='cartList' style={{ fontWeight: '600' }}>
                        <div style={{ display: 'contents' }} className='cartItem' >
                            {
                                window.paymentMeth.map((item, index) => {
                                    console.log("selectedLocation.type", window.paymentMeth)
                                    return (
                                        <div key={index} style={{ padding: '6px 0' }} onClick={() => this.setState({ selectedPayment: item })}>
                                            <Icon style={{ color: 'var(--themeColor)' }} name={selectedPayment === item ? 'selected radio' : 'circle outline'} />
                                            <span style={{ padding: '4px' }}>
                                                {strings[selectedLocation.type === 'dineIn' ? (item + 'DineIn') : selectedLocation.type === 'pickup' ? (item + 'Pickup') : item]}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            {
                                selectedPayment === 'online' && <>

                                    {
                                        cardObject && Object.keys(cardObject).map((record, index) => {
                                            let card = cardObject[record];
                                            return (
                                                <div key={index} style={{
                                                    margin: '16px 0',
                                                    // width: '250px',
                                                    padding: '12px 6px',
                                                    // border: '1px solid',
                                                    borderRadius: '6px',
                                                    // direction: 'ltr',
                                                    display: 'flex',
                                                    background: 'rgba(246, 246, 246, 1)',
                                                    justifyContent: 'space-between'
                                                }} onClick={() => this.setState({ selectedCard: card })}>
                                                    <div style={{ display: 'flex', width: '95%' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {/* <div>{customerObject.first_name + " " + customerObject.last_name}</div> */}
                                                            <Icon
                                                                floated='right'
                                                                name='credit card outline'
                                                                size='large'
                                                            />
                                                        </div>
                                                        {/* <div style={{ padding: '0 12px', fontSize: '10px', color: 'gray' }}>
                                                                {card.brand}
                                                            </div> */}
                                                        <div style={{ padding: '0 12px', fontSize: '14px', letterSpacing: '1px' }}>
                                                            {"XXXX " + card.last_four}
                                                        </div>

                                                    </div>

                                                    {
                                                        (selectedCard && (selectedCard.id === card.id)) && <Icon
                                                            // floated='right'
                                                            name='check circle'
                                                            size='large'
                                                            style={{ color: 'var(--themeColor)' }}
                                                        />

                                                    }
                                                    <p id="msg"></p>
                                                </div>
                                            )
                                        })

                                    }
                                    <div className='addNewCard' onClick={() => {
                                        this.setState({ addcard: true })
                                    }}>{strings.addNewCard}</div>

                                    {/* <Payment /> */}
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className='listDetails' style={{ boxShadow: 'rgba(0, 0, 0, 0.03) 0px -4px 6px', marginBottom: '90px' }}>
                    <div style={{ padding: '0 16px 8px' }}>
                        {
                            coupon && <div key={'coupon'} className='cartItem' style={{ color: 'var(--themeColor)', fontWeight: '800', border: 'none', padding: '8px', margin: '16px 0', backgroundColor: 'var(--counterBtn)', borderRadius: '8px' }}>
                                <div style={{ padding: '8px' }}>{coupon.code}</div>
                                <Button icon='cancel' className="removeBtn" basic onClick={() => this.props.setHelpers({})}></Button>
                            </div>
                        }
                        {
                            (percent || freeItem) ? ''
                                :
                                <div className='cartItem'>
                                    <div className='coupon'>
                                        <div style={{ display: 'flex', width: '100%', direction: 'ltr' }}>
                                            <Input style={{ width: '100%' }}
                                                onChange={(e, { value }) => this.setState({ couponeCode: value.toLowerCase() })}
                                                placeholder={strings.promoCode}
                                                labelPosition={direction === 'ltr' ? 'right' : 'left'}
                                                label={<Button className='ApplyCoupon'
                                                    onClick={() => user ?
                                                        applyCoupone(this.state.couponeCode, user, orderList, selectedLocation.type).then(result => {
                                                            if (result && result.error) {
                                                                return (_this.setState(result))
                                                            }
                                                            _this.props.setHelpers(result)
                                                        }

                                                        )
                                                        : this.setState({ login: true })}>
                                                    {strings.applyCoupone}
                                                </Button>} />
                                        </div>
                                    </div>
                                </div>

                        }

                    </div>

                    <div className='subDetail'>
                        <div>{strings.subtotal}</div>
                        <div>{receipt.subTotal + strings[window.currency]}</div>
                    </div>
                    {
                        receipt.delivery &&
                        <div className='subDetail'>
                            <div>{strings.delivery}</div>
                            <div>{receipt.delivery + strings[window.currency]}</div>
                        </div>
                    }
                    {
                        receipt.deductedValue ?
                            <div className='subDetail' style={{ color: 'var(--themeColor)' }}>
                                <div>{strings.discount} <span style={{ fontSize: '10px', color: '#cccccc' }}>({coupon ? coupon.code : 'loyalty pts'})</span></div>
                                <div>{" - " + receipt.deductedValue + strings[window.currency]}</div>
                            </div>
                            : ''
                    }

                    <div className='mainDetail'>
                        <div>{strings.total} <span style={{ fontSize: '10px', color: '#cccccc' }}>{strings.InclusiveOfVat}</span></div>
                        <div> {(receipt.total) + strings[window.currency]}</div>
                    </div>
                    {
                        error && <div className='error'>

                            <div className='discountContainer'>
                                <div className='discountContainer2' style={{ justifyContent: 'center' }}>
                                    <div className='text'>
                                        {error}
                                    </div>

                                </div>
                            </div>

                        </div>
                    }

                </div>
                <div className='actionBtn'>
                    <Button
                        className='placeOrderBtn'
                        loading={loading}
                        disabled={this.state.disableOrder || loading || (selectedLocation && selectedLocation.active && !selectedLocation.active.active)}
                        onClick={() => {
                            let _this = this;
                            this.setState({ loading: true }, () => {
                                receipt.paymentType = selectedPayment;
                                receipt.delivery = selectedLocation.fees;
                                receipt.branch = selectedLocation.branches;
                                receipt.specialNotes = specialNotes ? specialNotes : ''
                                receipt.resName = window.resName
                                receipt.path = window.pathName ? window.pathName : window.location.hostname
                                receipt.lang = direction
                                receipt.currency = window.currency;
                                if (selectedPayment === 'online') {
                                    if (!selectedCard) {
                                        return _this.setState({ error: 'Select Card', loading: false })
                                    }
                                    var makeCharge = functions().httpsCallable('AuthTransactions');
                                    selectedCard.phoneNumber = {
                                        country_code: user.info.counterCode,
                                        number: user.info.number
                                    }
                                    _this.props.updateUserAddress(receipt.selectedAddress)
                                    let redirectUrl = window.location.href.split('?')[0]
                                    makeCharge({ id: _this.state.tokenId, receipt: receipt, resKey: window.resKey, card: selectedCard, redirect: redirectUrl }).then(function (result) {
                                        // Read result of the Cloud Function.
                                        console.log("result", result)
                                        let { data } = result
                                        let { card, customer, id, status, transaction, errors } = data
                                        if (errors) {
                                            return _this.setState({ error: errors[0].description, loading: false })
                                        }
                                        card.customer = customer;
                                        receipt.destinationId = window.destinationId;
                                        if (status === "AUTHORIZED") {
                                            user.customer = customer
                                            placeOrder(orderList, user, freeItem, percent, coupon, receipt, id, 'orders').then(resut => {
                                                console.log("tokenId", _this.state.tokenId)
                                                _this.setState(resut);
                                                if (card && card.id) {
                                                    firestore().collection('users').doc(auth().currentUser.uid).update({
                                                        [`card.${card.id}`]: card
                                                    })
                                                }
                                                if (!resut.error) {
                                                    _this.props.flushOrders()
                                                }
                                            })
                                        } else if (status === "INITIATED") {
                                            placeOrder(orderList, user, freeItem, percent, coupon, receipt, id, 'pendingOrders').then(resut => {
                                                console.log("tokenId", _this.state.tokenId)
                                                _this.setState(resut);
                                                if (!resut.error) {
                                                    window.location.href = transaction.url
                                                }
                                            })
                                        } else {
                                            _this.setState({ error: 'Cannot Autorize this Transaction', loading: false })
                                        }
                                    }).catch(error => {
                                        console.log("errorerror", error)

                                    });
                                } else {
                                    placeOrder(orderList, user, freeItem, percent, coupon, receipt, false, 'orders').then(resut => {
                                        this.setState(resut, () => {
                                            if (!resut.error) {
                                                this.props.flushOrders()
                                            }
                                        });
                                    })
                                }
                            });
                        }}>

                        {strings.placeOrder}
                    </Button>
                </div>

            </div>

        )
    }
    renderAddressPicker() {
        let { user } = this.props
        let strings = this.strings
        let { addresses, direction } = this.state
        return (
            <Dimmer active page className='dimmer' >
                <div className='selectAddress' >
                    {/* <Icon className='iconTimes' name='times' icon='cancel' onClick={() => this.setState({ selectAddress: false })}></Icon> */}
                    <div className='cartDetail'>
                        <div className='title'>

                            <div style={{ margin: '10px', marginBottom: '20px', textAlign: 'start' }}>{strings.WhereToDeliver}</div>
                            <div className='cartList' style={{ margin: '8px 0', fontWeight: '600', maxHeight: '75vh', overflow: 'auto' }}>
                                {
                                    user && user.info && user.info.fullAddress && <div className='cartItem' onClick={() => this.setState({ selectAddress: false, selectedAddress: false })}>
                                        <div>
                                            <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>
                                            {user && user.info ? user.info.area : ''}
                                            <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                {user && user.info ? user.info.fullAddress : ''}
                                            </div>

                                        </div>
                                    </div>

                                }

                                {
                                    addresses && Object.keys(addresses).map((item, index) => {
                                        let addrs = addresses[item]
                                        console.log("addrs", addrs)
                                        return (
                                            <div key={index} className='cartItem' style={{ borderBottom: '1px solid #cccccc40', opacity: addrs.disabled ? '0.4' : '1' }} onClick={() => this.setState(addrs.disabled ? {} : { selectedAddress: addrs, selectAddress: false })}>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>
                                                    {addrs.label ? addrs.label : addrs.area}
                                                    <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                        {(addrs.area && addrs.area.name ? (direction === 'rtl' ? addrs.area.name_ar : addrs.area.name) : addrs.area) + " - " + addrs.fullAddress}
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className='cartItem' style={{ border: 'none' }}>
                                    <Button className='tag' onClick={() => this.setState({ pageStep: 2, selectAddress: false })}>{strings.addNewAddress} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dimmer>
        )

    }
    submitNewAddress() {
        let { selectedLocation, user } = this.props

        let { address, location, notes, label, newLocation, building, street, floor, flat, direction } = this.state


        this.setState({ loading: true }, () => {
            let name = this.state.name ? this.state.name : user && user.info && user.info.name
            let newEmail = this.state.email ? this.state.email : user && user.info && user.info.email
            let uid = auth().currentUser.uid
            if (!selectedLocation || !name || !building || !street || !floor || !flat || !newEmail) {
                this.setState({ error: direction === 'rtl' ? 'يرجى تعبئة جميع البيانات المطلوبة!' : 'Please fill all required fields!', loading: false })
                return
            }
            let id = Date.now()
            let post = {
                area: address ? address : selectedLocation ? selectedLocation.location : '',
                address: address ? address : '',
                location: location ? location : '',
                fullAddress: [street, building, ('floor:' + floor), ('flat' + flat)].join(' - '),
                notes: notes ? notes : '',
                label: label ? label : 'Home'
            }
            firestore().collection('users').doc(uid).update({
                name: name,
                email: newEmail ? newEmail : '',
                [`addresses.${id}`]: post
            }).then(() => {
                console.log('uiduid', uid)
                if (newLocation) {
                    this.props.updateUserLocation(newLocation)
                }
                this.setState({ pageStep: 1, selectedAddress: post, loading: false, newLocation: false, error: false })

            }).catch((error) => {
                this.setState({ error: error.message, loading: false })
            })
        })

    }
    submitNewUserInfo() {
        let { user } = this.props
        let name = this.state.name ? this.state.name : user && user.info && user.info.name
        let newEmail = this.state.email ? this.state.email : user && user.info && user.info.email
        let uid = auth().currentUser.uid
        firestore().collection('users').doc(uid).update({
            name: name,
            email: newEmail ? newEmail : ''
        }).then(() => {
            this.setState({ pageStep: 1, loading: false, error: false })
        }).catch((error) => {
            this.setState({ error: error.message, loading: false })
        })
    }
    renderUserInfo() {
        let strings = this.strings
        let { user } = this.props
        let { error, loading, direction } = this.state

        return (
            <div className='checkout'>
                <div className='cartDetail'>
                    <div className='title'>
                        {strings.addressDetails}
                    </div>
                    <div className='cartList' >
                        {
                            !user || !user.info || !user.info.name ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.name}</div>
                                    <Input fluid placeholder={direction === 'rtl' ? 'اسمك' : 'Your Name'} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            !user || !user.info || !user.info.email ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.email}</div>
                                    <Input fluid placeholder='name@email.com' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            error && <div className='error'>{error}</div>
                        }
                    </div>
                    <Button loading={loading} className='placeOrderBtn' onClick={() => this.submitNewUserInfo()}>{strings.save}</Button>

                </div>
            </div>

        )
    }
    renderAddAddress() {
        let strings = this.strings
        let { selectedLocation, user, allLocations } = this.props
        let { type } = selectedLocation
        if (type === "pickup") {
            return this.renderUserInfo()

        }
        let { label, error, loading, direction } = this.state
        let dropdownValue = this.state.address
        if (selectedLocation && !this.state.address) {
            if (selectedLocation.location.name) {
                dropdownValue = direction === 'rtl' && selectedLocation.location.name_ar ? selectedLocation.location.name_ar : selectedLocation.location.name
            } else {
                dropdownValue = selectedLocation.location
            }
        }
        let optimizedLocations = JSON.parse(JSON.stringify(allLocations)).map(item => {
            if (item.text.name) {
                item.text = direction === 'rtl' ? item.text.name_ar : item.text.name
                item.value = direction === 'rtl' ? item.value.name_ar : item.value.name
            }
            return item
        })
        return (
            <div className='checkout'>
                <div className='cartDetail'>
                    <div className='title'>
                        {strings.addressDetails}
                    </div>
                    <div className='cartList' >

                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.areaName}</div>
                            <Dropdown
                                search
                                style={{ padding: '14px 8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'rgba(247, 247, 247, 1)' }}
                                options={optimizedLocations}
                                scrolling
                                direction={direction === 'rtl' ? 'left' : 'right'}
                                selectOnBlur={false}
                                value={dropdownValue}
                                fluid
                                onChange={(e, { value }) => {
                                    let item = optimizedLocations.filter(item => item.value === value)[0];
                                    let originalLocation = allLocations.filter(originalItem => originalItem.key === item.key)
                                    let newLocation = {
                                        location: originalLocation.length > 0 ? originalLocation[0].value : value,
                                        fees: item.fees,
                                        branches: item.branches,
                                        minOrder: item.minOrder,
                                        locationId: item.locationId,
                                        activeMenu: selectedLocation.activeMenu,
                                        type: selectedLocation.type
                                    }
                                    this.setState({ address: value, newLocation, error: false })
                                }
                                }
                                placeholder='business bay'
                            >
                            </Dropdown>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.street}</div>
                            <Input fluid placeholder={direction === 'rtl' ? 'شارع الشيخ زايد' : 'Sheikh zayed st'} value={this.state.street} onChange={(e) => this.setState({ street: e.target.value, error: false })}></Input>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.building}</div>
                            <Input fluid placeholder={direction === 'rtl' ? 'عمارة 23' : 'building no 23'} value={this.state.building} onChange={(e) => this.setState({ building: e.target.value, error: false })}></Input>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '35%' }}>
                                    <div className='itemTitle'>{strings.floor}</div>
                                    <Input fluid placeholder='13' value={this.state.floor} onChange={(e) => this.setState({ floor: e.target.value, error: false })}></Input>
                                </div>
                                <div style={{ width: '60%' }}>
                                    <div className='itemTitle'>{strings.flat}</div>
                                    <Input fluid placeholder='1302' value={this.state.flat} onChange={(e) => this.setState({ flat: e.target.value, error: false })}></Input>
                                </div>
                            </div>
                        </div>
                        {/* <div className='cartItem' style={{ display: 'block' }} >
                                <div>{strings.fullAddress}</div>
                                <TextArea style={{ padding: '8px 0 0', width: '100%', border: 'none', outline: 'none' }} rows={2} transparent fluid placeholder='House no. /Flat no. /Floor /Building' value={this.state.fullAddress} onChange={(e) => this.setState({ fullAddress: e.target.value })}></TextArea>
                            </div> */}
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.addressLabel}</div>
                            <div style={{ display: 'flex' }}>
                                {
                                    (direction === 'rtl' ? ['منزل', 'مكتب', 'آخر'] : ["Home", "Office", "Other"]).map(item => {
                                        return (
                                            <Button key={item} basic className={item === label || item === this.state.otherAddressLabel ? 'tagSelected ' : 'tag'} onClick={() => this.setState({ label: item, otherAddressLabel: item, error: false })}>{item}</Button>
                                        )
                                    })
                                }


                            </div>
                            {
                                ['آخر', 'Other'].includes(this.state.otherAddressLabel) &&
                                <Input style={{ padding: '12px 0 0', }} fluid placeholder={direction === 'rtl' ? 'منزل' : 'ex. Home'} onChange={(e) => this.setState({ label: e.target.value, error: false })} />
                            }
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.notes}</div>
                            <Input style={{ width: '100%', border: 'none', outline: 'none' }} fluid placeholder={direction === 'rtl' ? 'خلف محطة ....' : 'ex. behind ... '} value={this.state.notes} onChange={(e) => this.setState({ notes: e.target.value, error: false })}></Input>
                        </div>
                        {
                            !user || !user.info || !user.info.name ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.name}</div>
                                    <Input fluid placeholder={direction === 'rtl' ? 'اسمك' : 'Your Name'} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            !user || !user.info || !user.info.email ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.email}</div>
                                    <Input fluid placeholder='name@email.com' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            error && <div className='error'>{error}</div>
                        }
                    </div>

                    <Button loading={loading} className='placeOrderBtn' onClick={() => this.submitNewAddress()}>{strings.saveAddress}</Button>

                </div>
            </div>
        )

    }
}

function mapStateToProps({ userProp, orderList, loyalty }) {
    return {
        orderList: orderList,
        user: userProp ? userProp.user : {},
        selectedLocation: userProp ? userProp.location : {},
        allLocations: userProp ? userProp.allLocations : [],
        userAddressProp: userProp ? userProp.userAddressProp : {},
        loyalty,
        helpers: userProp ? userProp.helpers : {},
        activeOrder: userProp ? userProp.activeOrder : false

    }
}

const mapDispatchToProps = dispatch => ({
    flushOrders: () => dispatch(flushOrders()),
    updateUserLocation: (location) => dispatch(updateUserLocation(location)),
    updateUserAddress: (address) => dispatch(updateUserAddress(address)),
    setHelpers: (helpers) => dispatch(setHelpers(helpers))

});
export default connect(mapStateToProps, mapDispatchToProps)(Cart)