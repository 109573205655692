import localForage from "localforage";
import newOrder from './reducers/newOrder';
import userReducer from './reducers/userReducer';
import loyalty from './reducers/loyaltyReducer';
import recentOrders from './reducers/recentOrdersReducers';

import { persistCombineReducers } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage: localForage,
}
const rootReducer = persistCombineReducers(persistConfig, {
  orderList: newOrder,
  userProp: userReducer,
  loyalty: loyalty,
  recentOrders: recentOrders

});

export default rootReducer;