import React from 'react';
import './categories.css'
import { Link } from 'react-router-dom'
const Categories = ({ menu, direction }) => {
    return (
        <div className='categouriesList'>
            {
                menu.map((item, index) => {
                    let { category } = item
                    let categoryItems = item.items
                    let firstItem = categoryItems && categoryItems.length ? categoryItems[0] : { pictures: [''] }
                    let picture = category.pic ? category.pic : (firstItem.pictures && firstItem.pictures.length) ? firstItem.pictures[firstItem.pictures.length - 1] : ''
                    return (
                        <Link to={(window.pathName ? ('/' + window.pathName) : '') + '/categories?id=' + encodeURIComponent(category.name)}
                            className='categouryItem'
                            style={{ backgroundImage: `linear-gradient(${direction === 'rtl' ? 'to top left' : 'to top right'}, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0) 66.81% , rgba(0, 0, 0, 0)), url(${picture})` }}
                            key={index}
                            id={category.name + 'tag'}
                        // onClick={() => getCategory(category)}
                        >
                            <div className='categouryTitle' >
                                {
                                    direction === 'rtl' ? category.name_ar : category.name
                                }
                            </div>

                        </Link>

                    )
                })
            }
            <div style={{ textAlign: 'center', color: '#9c9c9c', fontSize: '12px', padding: '12px', fontWeight: 'bold' }}><span role="img" aria-label="">⚡️</span>Food2order.io</div>


        </div>
    );
}

export default Categories;