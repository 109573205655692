import React, { Component } from 'react';
import './offlineBanner.css'
// import { Dimmer, Button, Checkbox, Icon } from 'semantic-ui-react'
import LocalizedStrings from 'react-localization';
import moment from "moment";

class Banner extends Component {
    state = {
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
    }
    componentWillMount() {
        let { direction } = this.state
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
    }
    strings = new LocalizedStrings({
        en: {
            msg: window.closedOn.title,
        },
        ar: {
            msg: window.closedOn.title_ar,
        }
    });
    render() {
        let { active } = this.props
        return (
            <div className='showBanner'>
                <div className='banner'>
                    {active && !active.active ? 'This Restaurant is closed Until ' + moment(active.duration).format('DD/MM/YYYY hh:mm A') : this.strings.msg}
                    {/* Delivery closed from 1AM - 9AM */}
                </div>
                {/* <div className='cancel' onClick={() => this.props.hide()} >
                    X
                </div> */}
            </div>
        )
    }

}
export default Banner