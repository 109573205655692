import React, { Component } from 'react';
import './newLogin.css'
import { Input, Dimmer, Icon, Dropdown, Button } from 'semantic-ui-react'
import { auth, firestore, functions } from 'firebase'
import { connect } from 'react-redux';
import { setHelpers, sendNotif } from '../../reducers/userReducer'
import LocalizedStrings from 'react-localization';
import { countryCodes } from '../Helpers/countryCodes'
import {
    checkCoponeValid
} from '../../helpers/cartHelpres'
// import Close_SVG from '../img/times.svg'
import { ReactComponent as ReactLogo } from './img/times.svg';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
            counterCode: window.counterCode ? window.counterCode : '971'
        }

    }
    strings = new LocalizedStrings({
        en: {
            login: "Login",
            loginToContinu: "Login to continue",
            createAccount: "Continue",
            verification: "Verification",
            name: "Full Name",
            phone: "Phone Number",
            saveInfo: "Save Info",
            enterVerificationCode: "Verify number 📲",
            verificationCodeSentTo: "Verification code sent to",
            wrongNumber: "Wrong number?",
            resend: "Resend",
            change: "Change it here",
            verify: "Verify",
            accountUpdated: "Account updated.",
            accountCreated: "Account created.",
            updatedSuccess: "Your account has been updated successfully",
            createSuccess: "Your account has been created successfully, click complete order to proceed.",
            dismiss: "Dismiss",
            youWillRecive: 'you will receive a code to verify mobile number',
            dontHaveAccount: "Don't have an account? ",
            haveAccount: "have an account? ",
            signup: "Sign up",
            signin: "Sign in",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            confirm: "Confirm",
            backToLogin: 'Back to Login',
            editnumber: 'Edit Phone Number',
            'Alert!': 'Alert!',
            'You have already earned points': 'Share with friends to earn more 🤩',
            'Congrats': "Congrats",
            ' is added to your wallet for the next order 🎁 ': ' has been added to your wallet 🎁 ',
            'You have earned': 'You have earned',
            from: ' from ',
            'Sent you a gift!': 'Sent you a gift!',
            Receive: "Receive",
            'in your wallet for the coming order when you login': 'in your wallet for the coming order when you login'
        },
        ar: {
            login: "تسجيل الدخول",
            loginToContinu: "تسجيل الدخول للاستمرار",

            createAccount: "استمر",
            verification: "التحقق",
            name: "الاسم الكامل",
            phone: "رقم الهاتف",
            saveInfo: "حفظ المعلومات",
            enterVerificationCode: "📲أدخل رمز التحقق",
            verificationCodeSentTo: "تم إرسال رمز التحقق إلى",
            wrongNumber: "اذا كان الرقم غير صحيح اضغط",
            resend: "إعادة إرسال",
            change: "تغيير",
            verify: "تأكيد",
            accountUpdated: "تم تحديث الحساب.",
            accountCreated: "تم إنشاء الحساب.",
            updatedSuccess: "تم تحديث حسابك بنجاح",
            createSuccess: "تم إنشاء حسابك بنجاح ، انقر فوق إكمال الطلب للمتابعة.",
            dismiss: "تم",
            youWillRecive: 'سوف تتلقى رمز للتحقق من رقم الهاتف المحمول',
            dontHaveAccount: "ليس لديك حساب؟ ",
            haveAccount: "لديك حساب؟ ",
            signup: "سجل",
            signin: "تسجيل الدخول",
            confirm: "تأكيد الطلب",
            backToLogin: 'العودة لتسجيل الدخول',
            editnumber: 'تحرير رقم الهاتف',
            'Alert!': 'تحذير',
            'You have already earned points': ' شارك مع الأصدقاء لكسب المزيد 🤩',
            'Congrats': "مبروك",
            ' is added to your wallet for the next order 🎁 ': ' 🎁تم إضافتهم في محفظتك بنجاح ',
            'You have earned': 'لقد حصلت على ',
            from: ' من ',
            'Sent you a gift!': 'أرسل لك هدية!',
            Receive: "إحصل على",
            'in your wallet for the coming order when you login': 'في محفظتك للطلب القادم عند تسجيل الدخول'



        }
    });

    componentWillMount() {
        this.strings.setLanguage(this.state.direction === 'rtl' ? 'ar' : 'en');
        let { user } = this.props
        if (user && user.info) {
            this.setState({
                name: user.info.name,
                number: user.info.number,
                counterCode: user.info.counterCode,
            })
        }
    }
    componentDidMount() {
        if (window.document.getElementById('recaptcha-container')) {
            window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                }
            });
            window.recaptchaVerifier.render()
        }
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'

    }

    render() {
        let { verification } = this.state
        if (verification) {
            return (
                <Dimmer active className='newLogin' page style={{ zIndex: '9999' }}>
                    <div id="recaptcha-container"></div>
                    {
                        this.renderVerification()
                    }
                </Dimmer>
            )
        }


        return (
            <Dimmer active className='newLogin' page style={{ zIndex: '9999' }}>
                <div id="recaptcha-container"></div>

                {
                    this.renderStep()
                }

            </Dimmer>
        )
    }
    submit() {
        this.setState({ loading: true })

        let { number, counterCode } = this.state


        if (!number || !counterCode) {
            this.setState({ error: 'Please fill all required fields', loading: false })
            return
        }

        this.autinticateMobile()
    }
    autinticateMobile() {
        let { number, counterCode } = this.state
        var appVerifier = window.recaptchaVerifier;
        let _this = this
        // auth().settings.appVerificationDisabledForTesting = true;

        auth().signInWithPhoneNumber(('+' + counterCode + number), appVerifier)
            .then(function (confirmationResult) {
                window.confirmationResult = confirmationResult;
                // let uid = auth().currentUser.uid
                // window.dataLayer.push({ 'userID': uid, 'event': 'submitAddressInfo' })
                _this.setState({ verification: true, error: null, loading: false })
            }).catch(function (error) {
                _this.setState({ error: error.message, loading: false })

            });

    }
    verifyCode() {
        let { code, number, counterCode, direction } = this.state
        let _this = this
        let strings = this.strings

        this.setState({ loading: true })

        if (this.props.edit) {
            var credential = auth.PhoneAuthProvider.credential(window.confirmationResult, code);
            auth().currentUser.updatePhoneNumber(credential).then((updateResult) => {
                _this.props.success()
                _this.setState({ completed: true, verification: false, error: null })

            }).catch(error => {
                _this.setState({ error: error.message, loading: false })
            })
        } else {
            window.confirmationResult.confirm(code).then(async function (result) {
                let uid = result.user.uid
                if (_this.props?.loyalty?.referal?.id && (_this.props?.loyalty?.referal?.id !== uid)) {
                    let userLoyalty = await firestore().collection('resturant').doc(window.resKey).collection('loyalty').doc(uid).get()
                    if (userLoyalty.data()?.referal) {
                        _this.props.sendNotif({ title: strings['Alert!'], msg: strings['You have already earned points'] })
                    } else {
                        if (_this.props?.loyalty?.viral) {
                            var reward = functions().httpsCallable('reward');
                            await reward({ uid: uid, resID: window.resKey, loyalty: _this.props?.loyalty })
                                .then((result) => {
                                    _this.props.sendNotif({ title: strings['Congrats'], msg: `${result.gift} ${window.currency}${strings[' is added to your wallet for the next order 🎁 ']}` })
                                });
                        }
                    }
                }
                console.log("result", result.additionalUserInfo)
                firestore().collection('users').doc(uid).set({
                    number: number,
                    counterCode: counterCode,
                }, { merge: true }).then(() => {
                    let ref = firestore().collection('resturant').doc(window.resKey).collection('offers').where('global', '==', true)
                    ref.get().then(offers => {
                        if (offers.empty) {
                            _this.props.setHelpers({})
                        } else {
                            offers.forEach(results => {
                                let data = results.data() ? results.data() : {};
                                let type = _this.props.selectedLocation.type;
                                if ((data.allow && !data.allow.includes(type)) || (data.endDate < Date.now())) {
                                    _this.props.setHelpers({})
                                } else {
                                    data.id = data.code
                                    let percent = data.percent
                                    data.coponeKey = results.id
                                    checkCoponeValid(window.resKey, results.id, data.limited).then(() => {
                                        _this.props.setHelpers({ percent: percent, cap: data.cap, couponeCode: data.code, coupon: data, discountApplied: (100 - percent) / 100 })
                                    }).catch((error) => {
                                        _this.props.setHelpers({})
                                    })
                                }
                            })
                        }
                    })
                    setTimeout(() => {
                        _this.props.success()
                        _this.setState({ completed: true, verification: false, error: null, loading: false })
                        window.dataLayer.push({
                            'userID': uid,
                            'resKey': window.resKey,
                            'lang': direction,
                            'event': 'UserLogin'
                        })


                    }, 1500)
                }).catch((error) => {
                    _this.setState({ error: error.message, loading: false })
                })
            }).catch(function (error) {
                _this.setState({ error: error.message, loading: false })
            });
        }

    }



    handleInputsChange(type, value) {
        let { number, counterCode } = this.state
        let newValue = {}
        newValue[type] = value

        this.setState(newValue, () => {
            this.setState({ enabled: number && (number.length >= 8) && counterCode ? true : false })
        })

    }

    CountryDropdown() {
        let { counterCode } = this.state
        return (
            <Dropdown
                search
                lazyLoad
                selectOnBlur={false}
                defaultValue={counterCode}
                placeholder={"ex:" + window.counterCode}
                style={{ backgroundColor: 'white', color: 'var(--themeColor)' }}
                onChange={(e, { value }) => this.setState({ counterCode: value })}
                options={countryCodes} />
        )

    }

    renderStep() {
        let { enabled, error, number, loading } = this.state
        let strings = this.strings
        let { referal, loyalty } = this.props

        return (
            <div className='popContainer' scroll="no">
                <div className='titleContainers'>
                    <div className='title'>{(this.props.edit) ? strings.editnumber : strings.login}</div>
                    <ReactLogo className='iconTimes' onClick={() => this.props.dismiss()} />
                    {/* <img src={ReactLogo} /> */}
                    {/* <Icon name='times' circular className='iconTimes' onClick={() => this.props.dismiss()} /> */}

                </div>
                {
                    loyalty?.viral && referal?.id && referal?.name &&
                    <div style={{ border: '1px dashed var(--themeColor)', margin: '3%', padding: '7% 3%', borderRadius: '8px', backgroundColor: 'var(--themeColorOpacity10)' }}>
                        <div style={{ fontSize: '600%', padding: '16px' }}>🎁</div>
                        <div style={{ color: '#4a4a4a', fontSize: '26px', fontFamily: 'poppins-bold,poppins-bold-ar' }}><span style={{ color: "var(--themeColor)", textTransform: 'capitalize' }}>{referal.name.split(' ')[0]} </span> {strings['Sent you a gift!']}</div>
                        <div style={{ color: '#4a4a4a', fontSize: '16px', padding: '10px', lineHeight: '18px' }}>{strings.Receive} <span style={{ fontFamily: 'poppins-bold,poppins-bold-ar' }}>{`${loyalty?.gift} ${window.currency}`} </span> {strings['in your wallet for the coming order when you login']}</div>

                    </div>

                }
                <div className='firstView'>
                    <div className='inputsContainer'>
                        <div className='inputsContainer_header' style={{ padding: '8% 0 16px' }}>{strings.loginToContinu}</div>

                        <div className='title' >{strings.phone}</div>
                        <Input
                            fluid
                            style={{ direction: 'ltr' }}
                            key='number'
                            label={this.CountryDropdown()}
                            labelPosition='left'
                            placeholder='xxxxxxxxxx'
                            value={number}
                            text={number}
                            onChange={(e, { value }) => this.handleInputsChange('number', value)}
                            type='number'
                            className='input'
                        />
                    </div>
                    <div style={{ textAlign: 'initial', color: 'rgba(199, 199, 199, 1)', fontSize: '13px', fontFamily: 'poppins-medium,poppins-medium-ar' }}>
                        {strings.youWillRecive}
                    </div>
                    {
                        error ?
                            <div className='error'>{error}</div>
                            : ''
                    }
                    <div className='loginBtncontainer'>
                        <Button disabled={!enabled} loading={loading} className='loginBtn' onClick={() => this.submit()}>{strings.createAccount}</Button>
                    </div>

                </div>
            </div>

        )

    }
    renderVerification() {
        let { error, loading } = this.state
        let strings = this.strings
        return (
            <div className='popContainer' scroll="no">
                <div className='titleContainers'>
                    <div className='title'>{strings.enterVerificationCode}</div>
                </div>
                <div className='verification'>
                    <div className='inputsContainer ' >
                        {/* <div className='title'>{strings.enterVerificationCode}</div> */}
                        <Input fluid type='tel' name='otp' placeholder='xxxxxx' value={this.state.code} className='input2' onChange={(e, { value }) => this.handleInputsChange('code', value)} />
                    </div>
                    <div className='inputsContainer'>
                        <div className='wrongNumber'>{strings.verificationCodeSentTo} <span >{this.state.counterCode + this.state.number}</span></div>
                    </div>
                    <div className='inputsContainer'>
                        <div className='wrongNumber' style={{ fontSize: '12px' }}>{strings.wrongNumber} <span style={{ cursor: 'pointer' }} onClick={() => this.setState({ verification: false })}>{strings.change}</span></div>
                    </div>
                    <div className='inputsContainer'>
                        <div className='resend' onClick={() => this.autinticateMobile()}>{strings.resend}</div>
                    </div>

                    {
                        error ?
                            <div className='error' style={{ padding: '4px 16px' }}>{error}</div>
                            : ''
                    }

                    <div className='loginBtncontainer' style={{ marginTop: '16px', paddingTop: '16px' }}>
                        <Button disabled={!this.state.code} loading={loading} className='loginBtn' onClick={() => this.verifyCode()}>{strings.verify}</Button>
                    </div>
                </div>

            </div>

        )

    }

}
function mapStateToProps(state) {
    console.log("statestate", state)
    return {
        user: state.userProp ? state.userProp.user : {},
        selectedLocation: state.userProp ? state.userProp.location : {},
        loyalty: state.loyalty
    }
}

const mapDispatchToProps = dispatch => ({
    setHelpers: (helpers) => dispatch(setHelpers(helpers)),
    sendNotif: (notif) => dispatch(sendNotif(notif))


});
export default connect(mapStateToProps, mapDispatchToProps)(Login)
