import React, { Component } from 'react';
import './header.css';
import { Icon, Button } from 'semantic-ui-react';
import LocalizedStrings from 'react-localization';

class Header extends Component {
    state = {
    }
    strings = new LocalizedStrings({
        en: {
            minOrder: " min order ",
            deliveryTime: " Delivery within",
            min: " min(s)",
            hour: " hour(s)",
            day: " day(s)",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            pts: ' Pts',
            inWallet: 'in your wallet',
            clickHere: 'Click here ',
            login: "to start earning points"
        },
        ar: {
            minOrder: " الحد الادنى ",
            deliveryTime: " التوصيل خلال ",
            min: " دقيقة",
            hour: " ساعة",
            day: " يوم",
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
            pts: ' نقطة',
            inWallet: ' في محفظتك',
            clickHere: 'إضغط هنا ',
            login: "لبدء كسب النقاط"

        }

    })
    componentWillMount() {
        let { direction } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
    }
    componentWillReceiveProps(prop) {
        let { direction } = this.props
        if (direction !== prop.direction) {
            this.strings.setLanguage(prop.direction === 'rtl' ? 'ar' : 'en');
        }
    }
    render() {
        let url = (window.location.pathname).split('/')
        let path = url[1]

        let { back, fromCart, title, minimize, direction } = this.props
        return (
            <div className='header' style={fromCart ? { width: '100%' } : minimize ? { position: 'sticky', top: '0', padding: '16px' } : {boxShadow:'none'}}>

                {
                    fromCart || minimize ?
                        <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', direction: 'ltr' }}>
                            <Icon name="arrow left"
                                onClick={() => {
                                    back ? back() :
                                        // (document.referrer.indexOf(window.location.host)) > -1 ? window.history.back() : 
                                        document.location.href = "/" + path
                                }} className='back'

                            ></Icon>
                            <div className='titleBar'>{minimize ? ((direction === 'rtl' && minimize.name_ar) ? minimize.name_ar : minimize.name) : title}</div>
                            <div className='back'></div>


                        </div>


                        :

                        this.renderNewHeader()
                }
            </div>
        )
    }

    renderNewHeader() {
        let { direction, location, user, showLoyalty, resLoyalty, login } = this.props
        let loyalty = resLoyalty ? (user && user.info ? (user.info.loyalty ? user.info.loyalty : { points: 0 }) : { login: true }) : false;
        let strings = this.strings
        return (
            <div style={{ width: '100%' }}>
                <div className='selectLocation'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 0 16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='resImage' style={{ backgroundImage: 'url("' + window.menuLogo + '")' }}>

                            </div>
                            <div className='resTitle'>
                                {window.resName}
                            </div>
                        </div>
                        <div>
                            {
                                this.state.loading ?
                                    <Button 
                                    loading={true} 
                                    style={{ cursor: 'pointer',backgroundColor:'transparent', width: '30px', minWidth: '30px', height: '30px'}}
                                    circular 
                                    className="cart"/>
                                    :
                                    <div
                                        className="cart"
                                        style={{ cursor: 'pointer', border: '2px solid var(--themeColor)', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', padding: '0', position: 'relative', width: '30px', height: '30px', fontFamily: direction === 'rtl' ? 'poppins-regular' : 'poppins-regular-ar' }}
                                        onClick={() => {
                                            this.setState({ loading: true }, () => {
                                                setTimeout(() => {
                                                    localStorage.setItem('direction', direction === 'rtl' ? 'ltr' : 'rtl');
                                                    this.strings.setLanguage(direction === 'rtl' ? 'en' : 'ar');
                                                    document.body.style.direction = (direction === 'rtl' ? 'ltr' : 'rtl');
                                                    this.props.updateLang(direction === 'rtl' ? 'ltr' : 'rtl');
                                                    let rightContainer = document.getElementById('rightSideContainer')
                                                    if (rightContainer) {
                                                        rightContainer.style.left = direction === 'rtl' ? 'auto' : '0'
                                                        rightContainer.style.right = direction === 'rtl' ? '0' : 'auto'

                                                    }

                                                    this.setState({ loading: false });
                                                }, 1000);
                                            })

                                        }}>
                                        <div>
                                            {direction === 'rtl' ? 'EN' : 'ع'}
                                        </div>

                                    </div>

                            }
                        </div>
                    </div>

                    {
                        (location && location.deliveryTime) || (location && location.minOrder) ?
                            <div className='tagView'>
                                {
                                    location && location.deliveryTime ? <div className='tag' style={direction === 'rtl' ? { padding: '0  0 0 16px' } : { padding: '0 16px 0 0' }}>
                                        <div className='icon'>
                                            <Icon name='clock outline'></Icon>
                                        </div>
                                        <div className='text'>
                                            {strings.deliveryTime + " " + location.deliveryTime.value + strings[location.deliveryTime.label]}
                                        </div>
                                    </div>
                                        : ''
                                }

                                {
                                    location && location.minOrder ? <div className='tag' style={direction === 'rtl' ? { padding: '0  0 0 16px' } : { padding: '0 16px 0 0' }}>
                                        <div className='icon'>
                                            <Icon name='dollar sign'></Icon>
                                        </div>
                                        <div className='text'>
                                            {strings.minOrder + location.minOrder + " " + strings[window.currency]}
                                        </div>

                                    </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }



                </div>
                {
                    loyalty ?
                        <div className='loyaltyContainer' onClick={() => {
                            if (!loyalty.login) {
                                showLoyalty()
                            }
                        }}>
                            <div>
                                {
                                    loyalty.login ?
                                        <>
                                            <span style={{ fontSize: '18px' }} role='img' aria-label="">🎉</span> <Button basic className='action' onClick={() => login()}><span style={{ color: 'var(--themeColor)', textDecoration: 'underline' }}> {strings.clickHere}</span> {strings.login}</Button>
                                        </>
                                        :
                                        <>
                                            <span style={{ fontSize: '18px' }} role='img' aria-label="">🎉</span> <span className='pts'>{(loyalty.points ? loyalty.points : 0) + strings[window.currency]} </span> {strings.inWallet}
                                        </>

                                }
                            </div>

                            <Icon circular name='info' bordered style={{ cursor: 'pointer', color: 'var(--themeColor)', margin: '0', boxShadow: `0 0 0 0.15em var(--themeColor) inset` }} onClick={() => showLoyalty()} />
                        </div>
                        : ''
                }

                <div className='selectLocationView' onClick={() => {
                    if (location && location.type === 'dineIn') {
                        return
                    };
                    this.props.showSelectLocation();
                }
                }>
                    {
                        this.props.showSelectLocation && <>
                            <div style={{ backgroundColor: 'var(--themeColor)', position: 'absolute', height: '50px', opacity: '0.05', width: '100%' }} />
                            <div style={{ display: 'flex', alignItems: 'center', padding: '16px', height: '50px', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon className='icon' name='map marker alternate'></Icon>
                                    <div className='deliveryType'>{
                                        (location && location.type) ?
                                            (location.type === 'pickup' ?
                                                ((direction === 'rtl') ? 'استلم الطلب من :'
                                                    : 'Pickup from:')
                                                : location.type === 'dineIn' ?
                                                    ((direction === 'rtl') ? 'يقدم الى: ' : 'Serving To:')



                                                    : ((direction === 'rtl') ? 'التوصيل إلى :' : 'Delivering to:')) : 'Delivering to'}</div>
                                    <div className='location'>{
                                        location ?
                                            (location.type === 'dineIn' ?
                                                (location.location ? ((direction === 'rtl' ? 'طاولة رقم ' : 'Table #') + location.location) : (direction === 'rtl' ? ' داخل المطعم' : 'Dine in'))
                                                : (location.location&&location.location.name ? (direction === 'rtl' ? location.location.name_ar : location.location.name) : location.location))
                                            : 'Select Location'}</div>

                                </div>
                                {
                                    location && location.type !== 'dineIn' ?
                                        <div style={{ fontSize: '22px' }}>
                                            <Icon className='icon' name='angle down'></Icon>
                                        </div>
                                        : ''
                                }

                            </div>
                        </>
                    }



                </div>
            </div>
        )
    }

}

export default Header