import React, { Component } from 'react';
import './thankyoupage.css'
import checkimg from '../../MenuComponents/img/checkimg.svg'
import LocalizedStrings from 'react-localization';
import { Link } from 'react-router-dom'
import { auth, firestore } from 'firebase';
import { Dimmer, Loader } from 'semantic-ui-react';

class ThankYouPage extends Component {
    state = {
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',

    }
    strings = new LocalizedStrings({
        en: {
            orderSent: "Your Order has been sent 👏",
            trackOrder: "Track order"
        },
        ar: {
            orderSent: "تم إرسال طلبك 👏",
            trackOrder: "تتبع طلبك"

        }
    });
    // componentWillMount() {
    //     let { direction } = this.state
    //     this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
    // }
    componentDidMount() {
        let _this = this
        let { user } = this.props
        let element = document.getElementById('fb-messenger-checkbox')
        let user_ref = Date.now()
        if (element && user && user.info && !user.info.PSID) {
            this.setState({ loading: true }, () => {
                setTimeout(()=>{
                    _this.setState({ loading: false })
                },1500);
                element.setAttribute('user_ref', user_ref)
                element.setAttribute('ref', auth().currentUser.uid)

                window.FB.Event.subscribe('messenger_checkbox', function (e) {
                    console.log("messenger_checkbox event");
                    if (e.event === 'rendered') {
                        console.log("Plugin was rendered");
                    } else if (e.event === 'checkbox') {
                        var checkboxState = e.state;
                        _this.setState({ chicked: checkboxState === 'checked', user_ref: e.user_ref })
                        console.log("Checkbox state: " + checkboxState);
                    } else if (e.event === 'not_you') {
                        console.log("User clicked 'not you'");
                    } else if (e.event === 'hidden') {
                        console.log("Plugin was hidden");
                    }
             
                })
                window.FB.XFBML.parse()
            })

        }
    }
    submit() {
        let { user_ref, chicked } = this.state

        if (chicked) {
            window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
                'app_id': '639127950286009',
                'page_id': '107675070834235',
                'ref': auth().currentUser.uid,
                'user_ref': user_ref
            });
            firestore().collection('users').doc(auth().currentUser.uid).update({
                messengerId: user_ref
            })
        }
    }

    render() {
        let strings = this.strings
        let path = window.pathName
        return (
            <div className='thankyouClass' >
                {
                    this.state.loading ?
                        <Dimmer page active>
                            <Loader active />
                        </Dimmer>
                        : ''

                }
                <div className='thankyouClassContainer' >
                    <img className='checkimg' alt='checkimg' src={checkimg} />
                    <div className='holatext' >Hola!</div>
                    <div className='yourordertext' >{strings.orderSent}</div>
                    <Link to={path ? ('/' + path + '/order-status') : '/order-status'} onClick={() => {
                        this.submit()
                    }} className='trackorderbutton' >{strings.trackOrder}</Link>

                    <div id="fb-messenger-checkbox" class="fb-messenger-checkbox" origin={window.location.href}
                        page_id='107675070834235' messenger_app_id='639127950286009' allow_login="true" size="xlarge"
                        skin="light" center_align="true">
                    </div>
                </div>
            </div>
        )
    }

}


export default ThankYouPage
